// JavaScript Document

// Scripts written by Jordan Bearce @ Weblinx, Inc.

/*  Accordions */
const accItem = document.getElementsByClassName("accordionitem");
const accHD = document.getElementsByClassName("accordionitemheading");
let accPos = 0;
function toggleItem() {
    const itemClass = this.parentNode.className;
    for (accPos = 0; accPos < accItem.length; accPos++) {
        accItem[accPos].className = "accordionitem closeacc";
    }
    if (itemClass == "accordionitem closeacc") {
        this.parentNode.className = "accordionitem openacc";
    }
}
for (accPos = 0; accPos < accHD.length; accPos++) {
    accHD[accPos].addEventListener("click", toggleItem, { passive: true });
}
